var crypto = require("crypto")
var URLSafeBase64 = require("urlsafe-base64")

const APP_DOMAIN = process.env.GATSBY_appDomain
const APP_PHOTOS_SIGNING_KEY_NAME = process.env.GATSBY_photosSigningKeyName
const APP_PHOTOS_SIGNING_KEY = process.env.GATSBY_photosSigningKey

export default path => {
  var url = `https://photos.${APP_DOMAIN}/${path}`
  var key_name = APP_PHOTOS_SIGNING_KEY_NAME
  var key = APP_PHOTOS_SIGNING_KEY
  var expiration = Math.round(new Date().getTime() / 1000) + 3600 // 1 hour minutes after, in seconds

  // Decode the URL safe base64 encode key
  var decoded_key = URLSafeBase64.decode(key)

  // Build URL
  var urlToSign =
    url +
    (url.indexOf("?") > -1 ? "&" : "?") +
    "Expires=" +
    expiration +
    "&KeyName=" +
    key_name

  //Sign the url using the key and url safe base64 encode the signature
  var hmac = crypto.createHmac("sha1", decoded_key)
  var signature = hmac.update(urlToSign).digest()
  var encoded_signature = URLSafeBase64.encode(signature)

  //Concatenate the URL and encoded signature
  urlToSign += "&Signature=" + encoded_signature

  return urlToSign
}
