import * as React from "react"

// Crypto
import crypto from "crypto-js"

// Firebase
import { getFirebase, generateAuthCode } from "../js/firebase/firebase"

import SEO from "../components/seo/seo"

import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroller"
import withLocation from "../js/utils/with-location"
import signUrl from "../js/utils/sign-url"

const SESSION_COLLECTION = "fs_sessions"
const ACTIVE_SESSION_COLLECTION = "fs_sessions_active"
const RAW_PHOTOS_BUCKET = process.env.GATSBY_rawPhotosBucket

// Theme
import {
  LButton,
  LRow,
  LCol,
  LTitle,
  LText,
  LContent,
  LFPinCode,
  LNavEmptyFloating,
  LFooterLove,
  FFullPage,
  FFullPageContent,
  LSpin,
  LMasonGallery,
} from "../components/fs_theme"

// Image Modal
import Carousel, { Modal, ModalGateway } from "react-images"

// Style
import styled from "styled-components"

// CSS
import "antd/dist/antd.less"

class FSession_Page extends React.Component {
  constructor(props) {
    super(props)

    // Get session id and user id.
    this.sessionId = props.sessionId
    this.userId = props.params.u
    this.isActive = props.params.active || false

    // Set component properties
    this.code = null
    this.pinCode = null
    this.codeListener = null
    this.codeListenerRef = null
    this.session = null
    this.lastPhotosSnapshot = null
    this.isFetchingMorePhotos = false
    this.signinInProgress = false
    this.retrySessionLoadOnce = false

    // Scroll references
    this.pageTopRef = React.createRef()
    this.pageEndRef = React.createRef()

    // Set state properties
    this.state = {
      photos: [],
      selectedIndex: 0,
      modalIsOpen: false,
      isLoadingMore: false,
      canLoadMorePhotos: false,
      isContinueDisabled: true,
      isContinueLoading: false,
      isUserSignedIn: false,
      isSessionOwner: false,
      didRetrieveCode: false,
      sessionTitle: null,
      downloadLink: null,
      sessionDescription: null,
    }
  }

  componentDidMount() {
    // Load Firebase Lazily

    const lazyApp = import("firebase/app")
    const lazyDatabase = import("firebase/database")
    const lazyFirestore = import("firebase/firestore")
    const lazyStorage = import("firebase/storage")
    const lazyAuth = import("firebase/auth")

    Promise.all([
      lazyApp,
      lazyDatabase,
      lazyAuth,
      lazyFirestore,
      lazyStorage,
    ]).then(([firebase]) => {
      const auth = getFirebase(firebase).auth()
      const database = getFirebase(firebase).database()
      const firestore = getFirebase(firebase).firestore()
      const rawStorage = getFirebase(firebase)
        .app()
        .storage(RAW_PHOTOS_BUCKET)
      this.auth = auth
      this.database = database
      this.firestore = firestore
      this.rawStorage = rawStorage
      this.onReady()
    })
  }

  // Libraries are initialized and component is ready.
  onReady = () => {
    console.log("Is Active Session? ", this.isActive)

    // Ensure bucket is valid
    if (!this.sessionId || !this.userId) {
      // TODO: Show empty state
      console.log("Missing parameters. Show empty state")
      return
    }

    // Listen for user states
    this.auth.onAuthStateChanged(user => {
      console.log("Auth state did change.")

      // Check if valid user
      if (user && !user.isAnonymous) {
        console.log(`User signed in: ${user.uid}`)

        // Ensure user matches requested user id. Else sign out
        if (user.uid === this.userId) {
          console.log("Updating user is signed in.")

          // Update state
          this.signinInProgress = false
          this.setState({ isUserSignedIn: true })

          // Fetch Session
          let collection =
            this.props.path && this.isActive
              ? ACTIVE_SESSION_COLLECTION
              : SESSION_COLLECTION
          this.getSession(this.sessionId, collection)
        } else {
          console.log(
            `User id mismatch signing out. ${user.uid}!==${this.user}`
          )
          this.auth.signOut()
        }
      } else {
        console.log(`User is not signed in. Signing in...`)
        if (!this.signinInProgress) {
          this.signinInProgress = true
          this.setState({
            isUserSignedIn: false,
          })

          // Sign in
          this.signInUser()
        }
      }
    })
  }

  // Sign in user.
  signInUser = () => {
    if (!this.userId || this.auth.currentUser) {
      console.log("Missing the user id or the user is already signed in.")
      return
    }

    // Get a valid encrypted token and update visiblity
    let codeRef = this.database
      .ref("fs_auth_code")
      .child(this.userId)
      .child("code")

    // Listen for new code changes
    let codeListener = codeRef.on("value", snapshot => {
      const authCode = snapshot.val()

      // Ensure we got a valid auth code
      if (!authCode) {
        console.log("Unable to get auth code. Generating new code...")
        // Generate new code
        generateAuthCode(this.userId)
        return
      }

      console.log("Got new auth code!")

      // Update code
      this.onCode(authCode)
    })

    // Update auth visiblity to ensure the client will show the pin
    this.database
      .ref("fs_auth_code")
      .child(this.userId)
      .update({ isVisible: true })

    // Update listeners to remove later
    this.codeListener = codeListener
    this.codeListenerRef = codeRef
  }

  // Remove listener when completed
  removeCodeListener = () => {
    if (this.codeListenerRef && this.codeListener) {
      this.codeListenerRef.off("value", this.codeListener)
      this.codeListenerRef = null
      this.codeListener = null
    }
  }

  onCode = code => {
    // Save code to decrypt later
    this.code = code

    // Update state
    if (!this.state.didRetrieveCode) {
      this.setState({ didRetrieveCode: true })
    }
  }

  onPinCode = code => {
    // Save pin code to decrypt code later
    this.pinCode = code

    // Updated state
    if (code) {
      this.setState({ isContinueDisabled: false })
    } else {
      this.setState({ isContinueDisabled: true })
    }
  }

  onConintue = () => {
    if (this.pinCode && this.code) {
      // Start loading
      this.setState({ isContinueLoading: true })

      // Decrypt token and sign in user
      try {
        const tokenBytes = crypto.AES.decrypt(this.code, this.pinCode)
        const token = tokenBytes.toString(crypto.enc.Utf8)
        this.signInUserWithToken(token)
      } catch (error) {
        console.log(error)
        // TODO: Show error toast
        this.setState({ isContinueLoading: false })
      }
    }
  }

  signInUserWithToken = token => {
    // Sign in user with token
    this.auth
      .signInWithCustomToken(token)
      .catch(error => {
        console.log("Unable to sign in user with token", error)
        this.setState({ isContinueLoading: false })
      })
      .then(() => {
        // Update state and remove listener.
        this.setState({ isUserSignedIn: true, isContinueLoading: false })
        this.removeCodeListener()
      })
  }

  // Reset pin code to reenter
  resetPin = () => {
    // TODO: Add clear field and refoucus to first input
    this.pinCode = null
    this.setState({ isContinueDisabled: true })
  }

  // Get session
  getSession = (sessionId, collection) => {
    console.log(`Getting session for collection ${collection}...`)

    this.firestore
      .collection(collection)
      .doc(sessionId)
      .get()
      .then(snapshot => {
        // Ensure we get a valid snapshot
        if (!snapshot) {
          console.log("Unable to get session.")
          return
        }

        // Get session data updatte
        const session = snapshot.data()
        this.session = session

        if (session.downloadPath) {
          var storageRef = this.rawStorage.ref(session.downloadPath)
          storageRef
            .getDownloadURL()
            .then(url => {
              this.onSession(session, url)
            })
            .catch(error => {
              // Handle any errors
              this.onSession(session)
            })
        } else {
          this.onSession(session)
        }
      })
      .catch(error => {
        console.log("Unable to get session", error)
        if (this.retrySessionLoadOnce) {
          console.log(
            "Already retried session load once and it failed. Hanging up the hat"
          )
          return
        }
        this.retrySessionLoadOnce = true
        // Try the negation of the path.
        let collection =
          this.props.path && this.isActive
            ? SESSION_COLLECTION
            : ACTIVE_SESSION_COLLECTION
        this.getSession(sessionId, collection)
      })
  }

  onSession = (session, downloadUrl) => {
    const title = `${session.sessionTitle ? session.sessionTitle : ""}`
    const photographerName = `${
      session.creatorFirstName ? session.creatorFirstName : ""
    } ${session.creatorLastName ? session.creatorLastName : ""}`
    const photosTaken = `${
      session.sessionPhotosCount ? session.sessionPhotosCount : 0
    }`
    const participants = `2`
    const description = `${photosTaken} photos | 📸 ${photographerName} | ${participants} participants`

    // Update state
    this.setState({
      isSessionOwner: session.customerUserId == this.auth.currentUser.uid,
      sessionTitle: title,
      sessionDescription: description,
      downloadLink: downloadUrl,
    })

    // Get inital photos
    this.loadMorePhotos()
  }

  // Get more session photos lazy loading
  loadMorePhotos = () => {
    if (this.isFetchingMorePhotos) {
      console.log("Already fetching more photos ignoring...")
      return
    }

    console.log("Fetching photos...")

    // Update state
    this.setState({ isLoadingMore: true })
    this.isFetchingMorePhotos = true

    // Get parameters
    let sessionId = this.session._id
    let creatorId = this.session.creatorId
    let customerUserId = this.session.customerUserId

    // Build query

    var query
    if (this.lastPhotosSnapshot) {
      query = this.firestore
        .collection("fs_session_photos")
        .where("sessionId", "==", sessionId)
        .where("ownerId", "==", customerUserId)
        .where("creatorId", "==", creatorId)
        .orderBy("createdAt", "desc")
        .startAfter(this.lastPhotosSnapshot)
        .limit(25)
    } else {
      query = this.firestore
        .collection("fs_session_photos")
        .where("sessionId", "==", sessionId)
        .where("ownerId", "==", customerUserId)
        .where("creatorId", "==", creatorId)
        .orderBy("createdAt", "desc")
        .limit(25)
    }

    // Request more photos
    query
      .get()
      .then(snapshot => {
        // Get documents
        let documents = snapshot.docs

        // Get photos
        let newPhotos = documents.map(document => {
          return this.documentToDisplayPhoto(document.data())
        })

        // Get last snapshot
        let lastSnapshot =
          documents.length > 0 ? documents[documents.length - 1] : null

        if (lastSnapshot) {
          this.lastPhotosSnapshot = lastSnapshot
          this.setState({ canLoadMorePhotos: true })
          console.log("There's still more photos. Keep scrolling ;)")
        } else {
          this.setState({ canLoadMorePhotos: false })
          console.log("All photos are loaded. Happy scrolling :)")
        }

        // Add more photos
        this.addMorePhotos(newPhotos)

        // Update state
        this.isFetchingMorePhotos = false
        this.setState({ isLoadingMore: false })
      })
      .catch(error => {
        console.log("Error getting more session photos.", error)

        // Update state
        this.isFetchingMorePhotos = false
        this.setState({ isLoadingMore: false })
      })
  }

  documentToDisplayPhoto = document => {
    let thumbnail = signUrl(document.urls.regularPath)

    // Default to 1:1 aspect ratio
    var width = 1
    var height = 1

    // Get size from photo info
    if (document.info.width && document.info.height) {
      width = document.info.width
      height = document.info.height
    }

    let photo = {
      src: thumbnail,
      width: width,
      height: height,
    }

    return photo
  }

  onPhotoClicked = (event, photo) => {
    // Prevent event defaults
    event.preventDefault()

    // Update selected index
    const index = photo.index
    this.setState({ selectedIndex: index })

    // Show modal
    this.toggleModal()
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
  }

  addMorePhotos = newPhotos => {
    let updatedPhotos = this.state.photos.concat(newPhotos)
    this.setState({ photos: updatedPhotos })
  }

  scrollToBottom() {
    this.pageEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  scrollToTop() {
    this.pageTopRef.current.scrollIntoView({ behavior: "smooth" })
  }

  downloadPhotos() {}

  render() {
    const {
      photos,
      selectedIndex,
      modalIsOpen,
      canLoadMorePhotos,
      isContinueDisabled,
      isContinueLoading,
      isUserSignedIn,
      isSessionOwner,
      didRetrieveCode,
      sessionTitle,
      sessionDescription,
      downloadLink,
    } = this.state

    const title = sessionTitle || "Session"

    // Session view
    const signedIn = (
      <div>
        <LRow>
          <LCol span={4} />
          <LCol span={16}>
            <CenterTitle>{sessionTitle}</CenterTitle>
            <CenterText>{sessionDescription}</CenterText>
            <br />
            <CenterDiv>
              {downloadLink ? (
                <LButton href={downloadLink} type="primary">
                  Download Photos
                </LButton>
              ) : sessionTitle && isSessionOwner ? (
                <CenterText>
                  We are generating a download link for. We will send you a
                  notification when it's ready.
                </CenterText>
              ) : null}
            </CenterDiv>
          </LCol>
          <LCol span={4} />
        </LRow>
        <br />
        <br />
        {photos.length > 0 ? (
          <LMasonGallery
            photos={photos}
            direction={"column"}
            margin={5}
            onClick={this.onPhotoClicked}
          />
        ) : null}
      </div>
    )

    // Log in view
    const signedOut = (
      <FFullPage>
        <FFullPageContent>
          <LRow>
            <LCol span={4} />
            <LCol span={16} align="center">
              <div
                hidden={isUserSignedIn || (!isUserSignedIn && !didRetrieveCode)}
              >
                <FTitle level={3}>Enter Pin Code</FTitle>
                <FText>
                  Open the Fonoshoot app to recive code to unlock this session
                </FText>
                <br />
                <FPinContainer>
                  <LFPinCode onResult={this.onPinCode} />
                </FPinContainer>
                <br />
                <LButton
                  type="primary"
                  disabled={isContinueDisabled}
                  loading={isContinueLoading}
                  onClick={this.onConintue}
                >
                  Continue
                </LButton>
              </div>
            </LCol>
            <LCol span={4} />
          </LRow>
        </FFullPageContent>
      </FFullPage>
    )
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMorePhotos}
        hasMore={canLoadMorePhotos}
        loader={
          <div className="loader" key={0}>
            <LRow>
              <LCol span={8} />
              <LCol span={8}>
                <br />
                <CenterDiv>
                  <CenterSpinner />
                </CenterDiv>
                <br />
              </LCol>
              <LCol span={8} />
            </LRow>
          </div>
        }
      >
        <SEO title={title} />
        <div ref={this.pageTopRef} />
        <StyledLayout>
          <ModalGateway>
            {modalIsOpen ? (
              <Modal onClose={this.toggleModal} preventScroll={true}>
                <Carousel views={photos} currentIndex={selectedIndex} />
              </Modal>
            ) : null}
          </ModalGateway>
          {/* Hid the nav is modal is shown */}
          {modalIsOpen ? null : <LNavEmptyFloating />}
          <ContentLayout>
            <LContent>
              {/* Dynamically show the sign in/signed out views */}
              {isUserSignedIn ? signedIn : signedOut}
            </LContent>
          </ContentLayout>
          {/* Hide footer if modal is shown */}
          {modalIsOpen ? null : <LFooterLove />}
        </StyledLayout>
        <div ref={this.pageEndRef} />
      </InfiniteScroll>
    )
  }
}

// Styled components

const FPinContainer = styled.div`
  &&& {
    display: inline-block;
    max-width: 75%;
    padding: 20px 0px 25px 0px;
    @media (max-width: 450px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 100%;
    }

    @media (min-width: 360px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 65%;
    }

    @media (min-width: 1000px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 50%;
    }
  }
`
const FText = styled(LText)`
  &&& {
    max-width: 75%;
    display: inline-block;
    @media (max-width: 360px) {
      /* CSS that should be displayed if width is equal to or less than 800px goes here */
      max-width: 100%;
    }
  }
`

const FTitle = styled(LTitle)`
  &&& {
    color: black;
  }
`

const CenterSpinner = styled(LSpin)`
  text-align: center;
`

const CenterTitle = styled(LTitle)`
  &&& {
    text-align: center;
    color: black;
    font-weight: bold;
  }
`

const CenterDiv = styled.div`
  &&& {
    display: flex;
    justify-content: center;
  }
`

const CenterText = styled(LText)`
  &&& {
    display: inherit;
    text-align: center;
    color: black;
  }
`

const StyledLayout = styled(LContent)`
  background-color: white;
`

const ContentLayout = styled(LContent)`
  background-color: white;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 5px;
  margin-right: 5px;
`

FSession_Page.propTypes = {
  u: PropTypes.string,
}

export default withLocation(FSession_Page)
